import React from "react";
import SectionTitle from "../ui/SectionTitle";
import ServiceCard from "../ui/cards/ServiceCard";
import Ux from "../ui/icons/Ux";
import Responsive from "../ui/icons/Responsive";
import Workspace from "../ui/icons/Workspace";

const Services = () => {
  const services = [
    {
      title: "Strony WWW",
      description:
        "Mając na uwadze, iż są one najważniejszą wizytówką firmy zyskasz pewność, że wizualność i funkcjonalność mogą iść w parze.",
      image: <Ux />,
      url: "/oferta/strony-www",
    },
    {
      title: "Sklepy internetowe",
      description:
        "Platforma e-Commerce, która zostaje zaprojektowana, by spełnić wymogi Twojej branży, a dzięki swej mobilności służyć Twoim klientom.",
      image: <Responsive />,
      url: "/oferta/e-commerce",
    },
    {
      title: "Marketing internetowy",
      description:
        "Nasza znajomość wszystkich dostępnych na rynku narzędzi i obowiązujących trendów pomoże Tobie w przewidywalny sposób zwiększać przychody.",
      image: <Workspace />,
      url: "/oferta/marketing-internetowy",
    },
  ];

  return (
    <section id="uslugi" className="text-gray-600">
      <div className="container mx-auto px-5 py-10">
        <div className="mb-12">
          <SectionTitle color="dark" align="center">
            Usługi
          </SectionTitle>
        </div>
        <div className="flex flex-row flex-wrap md:flex-nowrap md:space-x-8">
          {services.map(({ image, title, description, url }, index) => {
            return (
              <ServiceCard
                key={index}
                title={title}
                description={description}
                image={image}
                url={url}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
