import * as React from "react";

function Ux(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
      <circle cx={256} cy={256} r={245} fill="#ffaf10" />
      <path fill="#445ea0" d="M174.63 379.83H282.63V426H174.63z" />
      <path fill="#2e4c89" d="M174.63 379.83H194.47199999999998V426H174.63z" />
      <path
        d="M333.134 450c0 2.75-2.25 5-5 5H129.131c-2.75 0-5-2.25-5-5v-20c0-2.75 2.25-5 5-5h199.003c2.75 0 5 2.25 5 5v20z"
        fill="#293d7c"
      />
      <path
        d="M143.973 450v-20c0-2.75 2.25-5 5-5h-19.842c-2.75 0-5 2.25-5 5v20c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#1a2b63"
      />
      <path
        d="M0 370.834c0 5.5 4.5 10 10 10h300.175v-66.951H0v56.951z"
        fill="#293d7c"
      />
      <path
        d="M19.842 370.834v-56.951H0v56.951c0 5.5 4.5 10 10 10h19.842c-5.5 0-10-4.5-10-10z"
        fill="#1a2b63"
      />
      <path
        d="M310.175 286.987c0-2.206 1.794-4 4-4h143.09V66.465c0-6.046-4.946-10.992-10.991-10.992H10.992C4.946 55.473 0 60.419 0 66.465v248.418h310.175v-27.896z"
        fill="#445ea0"
      />
      <path
        d="M310.175 286.987c0-2.206 1.794-4 4-4h121.107V82.457c0-2.75-2.25-5-5-5H26.984c-2.75 0-5 2.25-5 5v205.441c0 2.75 2.25 5 5 5h283.19l.001-5.911z"
        fill="#eef6ff"
      />
      <circle cx={228.63} cy={348.21} r={13.911} fill="#445ea0" />
      <path
        d="M416.361 206.376c0 2.75-2.25 5-5 5H252.904c-2.75 0-5-2.25-5-5v-72.718c0-2.75 2.25-5 5-5h158.457c2.75 0 5 2.25 5 5v72.718z"
        fill="#ffaf10"
      />
      <path
        d="M267.747 206.376v-72.718c0-2.75 2.25-5 5-5h-19.842c-2.75 0-5 2.25-5 5v72.718c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#ff9518"
      />
      <g fill="#c3ddf4">
        <path d="M299.266 240.121h-43.112c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h43.112c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM299.266 262.916h-43.112c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h43.112c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM351.28 240.121h-32.06c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h32.06c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM351.28 262.916h-32.06c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h32.06c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM411.772 240.121h-40.836c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h40.836c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM411.772 262.916h-40.836c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h40.836c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
      </g>
      <g fill="#ef8318">
        <path d="M359.365 111.084c0 2.75-2.25 5-5 5h-10.999c-2.75 0-5-2.25-5-5v-10.999c0-2.75 2.25-5 5-5h10.999c2.75 0 5 2.25 5 5v10.999zM387.363 111.084c0 2.75-2.25 5-5 5h-10.999c-2.75 0-5-2.25-5-5v-10.999c0-2.75 2.25-5 5-5h10.999c2.75 0 5 2.25 5 5v10.999zM415.361 111.084c0 2.75-2.25 5-5 5h-10.999c-2.75 0-5-2.25-5-5v-10.999c0-2.75 2.25-5 5-5h10.999c2.75 0 5 2.25 5 5v10.999z" />
      </g>
      <g fill="#69cdff">
        <path d="M190.898 257.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9a7.5 7.5 0 017.5 7.5 7.5 7.5 0 01-7.5 7.5zM156.898 257.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9a7.5 7.5 0 017.5 7.5 7.5 7.5 0 01-7.5 7.5zM122.897 257.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9a7.5 7.5 0 017.5 7.5 7.498 7.498 0 01-7.5 7.5zM88.897 257.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9a7.5 7.5 0 017.5 7.5c0 4.143-3.357 7.5-7.5 7.5zM220.148 226.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9a7.5 7.5 0 01-7.5 7.5zM220.148 192.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9a7.5 7.5 0 01-7.5 7.5zM220.148 158.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9a7.5 7.5 0 01-7.5 7.5zM50.147 226.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9c0 4.143-3.357 7.5-7.5 7.5zM50.147 192.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9c0 4.143-3.357 7.5-7.5 7.5zM50.147 158.106a7.5 7.5 0 01-7.5-7.5v-9a7.5 7.5 0 0115 0v9c0 4.143-3.357 7.5-7.5 7.5zM190.898 121.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM156.898 121.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM122.897 121.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5zM88.897 121.005h-9c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z" />
      </g>
      <path
        d="M200.148 224.505c0 2.75-2.25 5-5 5h-120c-2.75 0-5-2.25-5-5v-86c0-2.75 2.25-5 5-5h120c2.75 0 5 2.25 5 5v86z"
        fill="#5dc1d8"
      />
      <path
        d="M89.99 224.505v-86c0-2.75 2.25-5 5-5H75.147c-2.75 0-5 2.25-5 5v86c0 2.75 2.25 5 5 5H94.99c-2.75 0-5-2.25-5-5z"
        fill="#28a5a5"
      />
      <g fill="#52bbef">
        <path d="M234.145 121.009c0 2.75-2.25 5-5 5h-21c-2.75 0-5-2.25-5-5v-21c0-2.75 2.25-5 5-5h21c2.75 0 5 2.25 5 5v21zM68.145 123.009c0 2.75-2.25 5-5 5h-21c-2.75 0-5-2.25-5-5v-21c0-2.75 2.25-5 5-5h21c2.75 0 5 2.25 5 5v21zM234.145 260.883c0 2.75-2.25 5-5 5h-21c-2.75 0-5-2.25-5-5v-21c0-2.75 2.25-5 5-5h21c2.75 0 5 2.25 5 5v21zM68.145 260.883c0 2.75-2.25 5-5 5h-21c-2.75 0-5-2.25-5-5v-21c0-2.75 2.25-5 5-5h21c2.75 0 5 2.25 5 5v21z" />
      </g>
      <g fill="#1e99d6">
        <path d="M214.167 121.009v-21c0-2.75 2.25-5 5-5h-11.022c-2.75 0-5 2.25-5 5v21c0 2.75 2.25 5 5 5h11.022c-2.75 0-5-2.25-5-5zM48.167 123.009v-21c0-2.75 2.25-5 5-5H42.145c-2.75 0-5 2.25-5 5v21c0 2.75 2.25 5 5 5h11.022c-2.75 0-5-2.25-5-5zM214.167 260.883v-21c0-2.75 2.25-5 5-5h-11.022c-2.75 0-5 2.25-5 5v21c0 2.75 2.25 5 5 5h11.022c-2.75 0-5-2.25-5-5zM48.167 260.883v-21c0-2.75 2.25-5 5-5H42.145c-2.75 0-5 2.25-5 5v21c0 2.75 2.25 5 5 5h11.022c-2.75 0-5-2.25-5-5z" />
      </g>
      <path
        d="M435.458 366.773c-.155-.422-.143-.662-.121-.74.001 0 .062-.006.119-.006.114 0 .313.023.614.134l42.104 15.465v-94.638c0-2.75-2.25-5-5-5h-159c-2.75 0-5 2.25-5 5v114.8c0 2.75 2.25 5 5 5h135.98l-14.696-40.015z"
        fill="#5dc1d8"
      />
      <path
        d="M329.017 401.787v-114.8c0-2.75 2.25-5 5-5h-19.842c-2.75 0-5 2.25-5 5v114.8c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#28a5a5"
      />
      <path
        d="M436.415 365.222c-1.647-.605-2.501.248-1.896 1.896l26.365 71.782c.605 1.648 2.116 1.98 3.357.739l13.997-13.997a3.203 3.203 0 014.515 0l16.128 16.13a3.202 3.202 0 004.514 0l7.674-7.674a3.202 3.202 0 000-4.514l-16.129-16.129a3.202 3.202 0 010-4.514l13.996-13.997c1.242-1.24.909-2.751-.739-3.356l-71.782-26.366z"
        fill="#293d7c"
      />
    </svg>
  );
}

export default Ux;
