import * as React from "react";

function Responsive(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
      <circle cx={267} cy={256} r={245} fill="#69cdff" />
      <path fill="#445ea0" d="M185.72 358.53H264.43V399.76H185.72z" />
      <path fill="#2e4c89" d="M185.72 358.53H205.562V399.76H185.72z" />
      <path
        d="M316.856 398.763H127.56c-2.806 0-5.101 2.296-5.101 5.102v25.571c0 2.806 2.296 5.102 5.101 5.102h189.296v-35.775z"
        fill="#293d7c"
      />
      <path
        d="M142.301 429.435v-25.571c0-2.806 2.296-5.102 5.102-5.102H127.56c-2.806 0-5.101 2.296-5.101 5.102v25.571c0 2.806 2.296 5.102 5.101 5.102h19.842c-2.805 0-5.101-2.296-5.101-5.102z"
        fill="#1a2b63"
      />
      <path
        d="M445.054 58.964H5.102C2.296 58.964 0 61.26 0 64.066V354.43a5.116 5.116 0 005.102 5.102h311.754V206.768c0-3.859 3.14-7 7-7h126.3V64.066c0-2.806-2.296-5.102-5.102-5.102z"
        fill="#293d7c"
      />
      <path
        d="M22.396 308.514a3.069 3.069 0 003.061 3.06h291.399V206.768c0-3.859 3.14-7 7-7H427.76v-90.77H22.396v199.516z"
        fill="#fff"
      />
      <path
        d="M42.239 308.514V108.998H22.396v199.516a3.069 3.069 0 003.061 3.06H45.3a3.07 3.07 0 01-3.061-3.06z"
        fill="#d9eafc"
      />
      <circle cx={225.08} cy={335.46} r={13.774} fill="#445ea0" />
      <path
        d="M316.856 223.146h-53.424c-1.65 0-3 1.35-3 3v59.639c0 1.65 1.35 3 3 3h53.424v-65.639z"
        fill="#c3ddf4"
      />
      <path
        d="M280.274 285.785v-59.639c0-1.65 1.35-3 3-3h-19.842c-1.65 0-3 1.35-3 3v59.639c0 1.65 1.35 3 3 3h19.842c-1.65 0-3-1.35-3-3z"
        fill="#aec1ed"
      />
      <path
        d="M323.856 199.768h46.618v-65.382c0-2.75-2.25-5-5-5H84.682c-2.75 0-5 2.25-5 5v66.525c0 2.75 2.25 5 5 5h232.233c.424-3.456 3.373-6.143 6.941-6.143z"
        fill="#c3ddf4"
      />
      <path
        d="M99.524 200.91v-66.525c0-2.75 2.25-5 5-5H84.682c-2.75 0-5 2.25-5 5v66.525c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#aec1ed"
      />
      <path
        d="M424.699 84.983H25.457a3.07 3.07 0 00-3.061 3.062v21.953H427.76V88.045a3.07 3.07 0 00-3.061-3.062z"
        fill="#ffaf10"
      />
      <path
        d="M45.3 84.983H25.457a3.07 3.07 0 00-3.061 3.062v21.953h19.842V88.045a3.071 3.071 0 013.062-3.062z"
        fill="#ff9518"
      />
      <g fill="#5dc1d8">
        <path d="M171.504 238.979h-84.17c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h84.17c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM171.504 263.466h-84.17c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h84.17a7.5 7.5 0 010 15zM171.504 287.953h-84.17c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h84.17c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM237.56 238.979h-43.326c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h43.326c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM237.56 263.466h-43.326c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h43.326a7.5 7.5 0 010 15zM237.56 287.953h-43.326c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h43.326c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
      </g>
      <path
        d="M504 198.768c4.4 0 8 3.6 8 8v241.77c0 4.4-3.6 8-8 8H323.856c-4.4 0-8-3.6-8-8v-241.77c0-4.4 3.6-8 8-8H504z"
        fill="#445ea0"
      />
      <path
        d="M337.567 425.125c-2.75 0-5-2.25-5-5V219.18c0-2.75 2.25-5 5-5H490.29c2.75 0 5 2.25 5 5v200.945c0 2.75-2.25 5-5 5H337.567z"
        fill="#fff"
      />
      <path
        d="M352.409 420.125V219.18c0-2.75 2.25-5 5-5h-19.842c-2.75 0-5 2.25-5 5v200.945c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#d9eafc"
      />
      <path
        d="M404.567 445.125c-2.75 0-5-2.25-5-5v-1.945c0-2.75 2.25-5 5-5h18.723c2.75 0 5 2.25 5 5v1.945c0 2.75-2.25 5-5 5h-18.723z"
        fill="#445ea0"
      />
      <path
        d="M495.29 233.473V219.18c0-2.75-2.25-5-5-5H337.567c-2.75 0-5 2.25-5 5v14.293H495.29z"
        fill="#ffaf10"
      />
      <path
        d="M357.409 214.18h-19.842c-2.75 0-5 2.25-5 5v14.293h19.842V219.18c0-2.75 2.25-5 5-5z"
        fill="#ff9518"
      />
      <g fill="#5dc1d8">
        <path d="M448.013 324.933h-68.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h68.169a7.5 7.5 0 010 15zM448.013 348.06h-68.169a7.5 7.5 0 010-15h68.169a7.5 7.5 0 010 15zM448.013 371.185h-68.169a7.5 7.5 0 010-15h68.169a7.5 7.5 0 010 15z" />
      </g>
      <path
        d="M479.756 292.257c0 2.75-2.25 5-5 5H353.101c-2.75 0-5-2.25-5-5v-41.244c0-2.75 2.25-5 5-5h121.655c2.75 0 5 2.25 5 5v41.244z"
        fill="#c3ddf4"
      />
      <path
        d="M367.943 292.257v-41.244c0-2.75 2.25-5 5-5h-19.842c-2.75 0-5 2.25-5 5v41.244c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#aec1ed"
      />
      <path
        d="M461.757 409.416c0 2.75-2.25 5-5 5H371.1c-2.75 0-5-2.25-5-5v-16.91c0-2.75 2.25-5 5-5h85.657c2.75 0 5 2.25 5 5v16.91z"
        fill="#c3ddf4"
      />
      <path
        d="M385.942 409.416v-16.91c0-2.75 2.25-5 5-5H371.1c-2.75 0-5 2.25-5 5v16.91c0 2.75 2.25 5 5 5h19.842c-2.75 0-5-2.25-5-5z"
        fill="#aec1ed"
      />
    </svg>
  );
}

export default Responsive;
