import React, { useState } from "react";
import SectionSubtitle from "../ui/SectionSubtitle";
import SectionTitle from "../ui/SectionTitle";
import FormInput from "../ui/FormInput";
import FormTextarea from "../ui/FormTextarea";
import Pin from "../ui/icons/Pin";
import Phone from "../ui/icons/Phone";
import Mail from "../ui/icons/Mail";
import config from "../../../config.json";

const Contact = ({ addPadding = true }) => {
  const [isSend, setIsSend] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("contact-form");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setIsSend(true))
      .catch((error) => alert(error));
  };

  return (
    <section id="kontakt" className="bg-white relative">
      <div className={`container mx-auto px-5 ${addPadding ? "py-12" : ""}`}>
        <SectionTitle>Kontakt</SectionTitle>
        <SectionSubtitle>
          Chcesz porozmawiać na temat współpracy? <br />
          <span className="text-brown">Odezwij się</span> do nas.
        </SectionSubtitle>
        <div className="flex flex-wrap md:flex-nowrap space-x-8">
          <div className="w-full md:w-3/5">
            <form
              id="contact-form"
              className="mt-10"
              name="Contact Form"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              onSubmit={(e) => handleSubmit(e)}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Contact Form" />
              <FormInput
                name="name"
                placeholder="Imię i nazwisko"
                required={true}
              >
                Imię i nazwisko
              </FormInput>
              <FormInput
                name="email"
                placeholder="Adres e-mail"
                required={true}
              >
                E-mail
              </FormInput>
              <FormInput name="phone" placeholder="Telefon" required={true}>
                Telefon
              </FormInput>
              <FormTextarea
                name="message"
                placeholder="Wiadomość"
                required={true}
              >
                Telefon
              </FormTextarea>
              <button
                type="submit"
                className="bg-primary hover:bg-white hover:text-primary text-white py-4 px-10 
                mt-5 border-primary border-2 transition duration-500 uppercase focus:outline-none"
              >
                Wyślij
              </button>
            </form>
            {isSend && (
              <div class="bg-green-500 mt-8 p-6">
                <p className="text-xl text-white">Wiadomość została wysłana.</p>
              </div>
            )}
          </div>
          <div className="w-full md:w-2/5">
            <address>
              <div className="mb-5 mt-9">
                <Pin width="40" />
                <h4 className="text-lg text-bold mt-2">Adres</h4>
                <p className="text-gray-500">
                  Kruszwicka 7, <br /> 80-299 Gdańsk
                </p>
              </div>
              <div className="mb-5">
                <Phone width="40" />
                <h4 className="text-lg text-bold mt-2">Telefon</h4>
                <a href={`tel:${config.phone}`} className="text-gray-500 block">
                  {config.phone}
                </a>
              </div>
              <div className="mb-5">
                <Mail width="40" />
                <h4 className="text-lg text-bold mt-2">E-mail</h4>
                <a href={`mailto:${config.email}`} className="text-gray-500">
                  {config.email}
                </a>
              </div>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
