import React from "react";
import { Link } from "gatsby";

const Button = ({ url, children, color = "primary", size = "normal" }) => {
  return (
    <Link
      to={url}
      className={`${
        color === "primary"
          ? "bg-primary hover:bg-white hover:text-primary hover:border-primary"
          : "bg-transparent hover:border-opacity-70"
      }
        ${size === "normal" ? `text-lg py-3 px-10` : `text-base py-2 px-6`} 
      text-white mr-5 border-2 transition duration-500 inline-block border-primary rounded-3xl`}
    >
      {children}
    </Link>
  );
};

export default Button;
