import React from "react";

const FormInput = ({ name, placeholder, required = false }) => {
  return (
    <input
      id={name}
      type="text"
      name={name}
      placeholder={placeholder}
      className="block w-full p-3 my-3 
                    text-gray-800 appearance-none 
                    border-2 border-gray-100 bg-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
      required={required}
    />
  );
};

export default FormInput;
