import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Button from "../ui/Button";

const Portfolio = () => {
  const query = useStaticQuery(graphql`
    query {
      wdresie: file(relativePath: { eq: "portfolio/wdresie.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
      perfumcity: file(relativePath: { eq: "portfolio/perfumcity.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
      seepoint: file(relativePath: { eq: "portfolio/seepoint.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);
  console.log(query);

  const projects = [
    {
      title: "wdresie.pl",
      description:
        "Wykonaliśmy w pełni funkcjonalną platformę e-Commerce w branży sport-fashion. Sklep wyróżnia się nowoczesnym designem, autorskim systemem integracji z hurtowniami oraz przemyślanym procesem zakupowym.",
      image: query.wdresie.childImageSharp.gatsbyImageData,
      url: "/portfolio/wdresie",
    },
    {
      title: "perfumcity.pl",
      description:
        "Dla klienta z branży kosmetycznej przygotowaliśmy w pełni zautomatyzowany sklep internetowy. Produkty i stany magazynowe są synchronizowane z hurtownią, faktury wystawiają się same. Klient może skupić się na swoim biznesie i pracować nad sprzedażą.",
      image: query.perfumcity.childImageSharp.gatsbyImageData,
      url: "/portfolio/perfumcity",
    },
    {
      title: "seepoint.pl",
      description:
        "Drukarnia materiałów reklamowych otrzymała od nas nową firmową stronę www. Strona została przygotowana w dwóch językach. Zadbaliśmy o przeniesienie z poprzedniej wersji strony wszystkich podstron, produktów i wpisów z zachowaniem wypracowanych wcześniej zasad SEO.",
      image: query.seepoint.childImageSharp.gatsbyImageData,
      url: "/portfolio/seepoint",
    },
  ];

  return (
    <section className="relative">
      {projects.map((project, i) => {
        return (
          <div
            className={`portfolio-item ${i % 2 ? "bg-gray-100" : "bg-white"}`}
            key={i}
          >
            <div className="container mx-auto px-5 py-12 md:py-24">
              <div
                className={`flex flex-row flex-wrap items-center ${
                  i % 2 ? "justify-content-end" : ""
                }`}
              >
                <div
                  className={`md:w-1/2 ${i % 2 ? "md:order-1" : "md:order-2"}`}
                >
                  <GatsbyImage image={project.image} alt={project.title} />
                </div>
                <div
                  className={`md:w-1/2 ${i % 2 ? "md:order-2" : "md:order-1"}`}
                >
                  <h2 className="text-4xl font-bold mb-8">{project.title}</h2>
                  <p className="text-xl text-gray-500 mb-8">
                    {project.description}
                  </p>
                  <Button url={project.url}>zobacz więcej</Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Portfolio;
