import React from "react";
import Button from "../ui/Button";
import Animation from "../global/Animation";
import reactAnimation from "../../images/6853-cloud-animation.json";

const Hero = () => {
  return (
    <section className="bg-indigo-500">
      <div className="container mx-auto px-5">
        <div className="flex flex-row flex-wrap lg:flex-nowrap space-x-8 items-center justify-center h-screen">
          <div className="w-full lg:w-1/2 pt-0 sm:pt-48 lg:pt-0">
            <h2 className="text-gray-100 mb-8 text-3xl md:text-5xl font-bold">
              Budujemy strony i aplikacje internetowe
            </h2>
            <p className="text-gray-200 text-base md:text-xl mb-8">
              Pomagamy ujarzmić najnowsze technologie biznesom małym i dużym.
              Nasz software house skupia się przede wszystkim na wartości
              płynącej z wdrażanych rozwiązań.
            </p>
            <Button url="/#uslugi">zobacz więcej</Button>
          </div>
          <div className="w-full lg:w-1/2 hidden sm:block">
            <Animation animation={reactAnimation} width={500} height={500} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
