import React from "react";
import SectionTitle from "../ui/SectionTitle";

const AboutUs = () => {
  return (
    <section id="o-nas" className="bg-white relative">
      <div className="container mx-auto px-5 py-10 md:py-20 relative z-20">
        <div className="mb-14">
          <SectionTitle color="dark" align="center">
            Twój Software House w <span className="text-red-600">Gdańsku</span>
          </SectionTitle>
        </div>
        <div className="flex flex-row flex-wrap md:flex-nowrap md:space-x-10 text-justify">
          <div className="md:w-1/2">
            <p className="text-gray-500 text-lg mb-5">
              Staramy się aby nasze realizacje dowodziły, iż każdy ma szansę na
              to by ich strona www, bądź sklep on-line stanowczo zwiększyły
              sprzedaż oraz poprawiły wizerunek firmy w internecie. Stawiamy na
              autorskie, dopracowane w każdym szczególe oprogramowanie, z którym
              można współpracować zamiast walczyć.
            </p>
            <p className="text-gray-500 text-lg">
              Nasza agencja interaktywna została stworzona przez kreatywnych
              ludzi z pasją, którzy zawsze z zaangażowaniem pracują na styku
              biznesu i technologii, dostarczając najbardziej dostosowane
              rozwiązanie.
            </p>
          </div>
          <div className="md:w-1/2">
            <p className="text-gray-500 text-lg">
              Czego można się po nas spodziewać? Na pewno cechuje nas
              profesjonalizm. Każdy z naszych klientów jest inny, lecz wszyscy
              mamy wspólny cel – sukces. Dlatego też do każdego projektu
              podchodzimy nieszablonowo, jednocześnie stawiając aby nasze
              rozwiązania były przemyślane i w pełni skonsultowane z klientem.
              Efektem naszych działań jest kompletny produkt oparty na wcześniej
              ustalonych wymaganiach.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
