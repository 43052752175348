import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";

const Button = ({
  animation,
  height = 250,
  width = 250,
  loop = true,
  autoplay = true,
}) => {
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop,
      autoplay,
      animationData: animation,
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <div
      style={{ marginTop: 20, marginBottom: 20, height, width }}
      ref={animationContainer}
    />
  );
};

export default Button;
