import * as React from "react";
import SEO from "../components/global/Seo";
import Header from "../components/layout/Header";
import Hero from "../components/home/Hero";
import Services from "../components/home/Services";
import Portfolio from "../components/home/Portfolio";
import AboutUs from "../components/home/AboutUs";
import Contact from "../components/global/Contact";
import Footer from "../components/layout/Footer";

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO titleTemplate="%s" />
      <Header />
      <Hero />
      <AboutUs />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default IndexPage;
