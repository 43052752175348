import React from "react";
import Button from "../Button";

const ServiceCard = ({ image, title, description, url }) => {
  return (
    <div className="py-16 px-12 text-center relative overflow-hidden h-full border-gray-200 border-2 service-card mb-5 md:mb-0">
      {image}
      <h3 className="text-xl text-bold mb-5 text-gray-800">{title}</h3>
      <p className="text-gray-500 mb-7">{description}</p>
      <Button url={url} size="small">
        więcej
      </Button>
    </div>
  );
};

export default ServiceCard;
